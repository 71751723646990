<template>
	<div>
		<div class="hello">
			<div><img src="../assets/logos.png" style="height: 80px;" /></div>
			<div>爱陪伴</div>
		</div>
		<div class="div1">
			<div style="biaodan" v-if="!isLogin">
				<van-form @submit="onSubmit">
					<van-field v-model="phone" type="number" maxlength="11" name="手机号码" label="手机号码"
						placeholder="请填写下单时的手机的号码" :rules="[{ required: true, message: '请填写下单时的手机的号码' }]" />
					<van-field v-model="orderID" name="请填写订单号" label="请填写订单号" placeholder="请填写订单号"
						:rules="[{ required: true, message: '请填写订单号' }]" />
					<div style="margin: 16px;">
						<van-button round block type="info" native-type="submit">
							提交
						</van-button>
					</div>
				</van-form>
			</div>
<!-- 			<div>{{msg}}</div> -->
			<div style="biaodan" v-if="isLogin">
				<div v-if="!ifTijiao">
					<div style="text-align: left;color: gray;font-size: 12px;">
						请根据手机弹框提示授权录音，若苹果用户在微信内打开此页面不支持录音功能，可以将网址复制到百度、QQ、UC等第三方浏览器中打开。需要根据以下内容进行朗读,尽量在安静的环境下操作,并全程用普通话。</div>
					<div
						style="text-align: left;margin-top: 20px;border-radius: 5px;border: 1px solid lightgray;padding: 10px;line-height: 30px;">
						美国的第16任总统，他的名字叫：林肯。林肯小时候家境非常的贫寒，9岁的林肯就到了一家小百货商店打零工。
						在圣诞节的前一天晚上，商店打烊后，林肯跟往常一样清点钱款。数到最后的时候，他发现多出来12美分。
						林肯想：“怎么会多出了钱呢？那一定是我多收了别人的钱，会是谁呢？”林肯仔细地回想了一天的客人：“汤姆叔叔？不是。
						爱丽斯婶婶？也不是……对了！是格瑞太太！”林肯终于想到了。
						于是，林肯去找到老板，他有点不好意思，说到：“我多收了格瑞太太钱，我得给她送回去。”
						老板听了高兴地说：“小伙子，你做得对！只是格瑞太太住得很远，天又这么晚了，你还是等她下次来买东西时再给她吧！”
					</div>
					<div style="margin-top: 20px;">
						<div style="font-size:14px;">
							<h3>录音时长：{{ recorder && recorder.duration.toFixed(2) }}</h3>
							<div>
								<van-button type="info" size="small" @click="handleStart">开始录音</van-button>
								<van-button type="warning" size="small" style="margin-left: 5px;"
									@click="handleStop">停止</van-button>
								<van-button type="primary" size="small" style="margin-left: 5px;"
									@click="handlePlay">播放</van-button>
								<van-button type="default" size="small" @click="handleDestroy"
									style="margin-left: 5px;">销毁录音</van-button>
								<van-button type="danger" size="small" style="margin-left: 5px;"
									@click="uploadRecord">上传</van-button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="ifTijiao">
				<div>
					<van-steps direction="vertical" :active="active" style="text-align: left;">
						<van-step>
							<h3>买家下单</h3>
							<p style="font-size: 12px;">在此平台上传音频</p>
						</van-step>
						<van-step>
							<h3>音频制作中</h3>
							<p style="font-size: 12px;">音频正在马不停蹄的制作中，请客官耐心等待......</p>
						</van-step>
						<van-step>
							<h3>宝贝已发货</h3>
							<p style="font-size: 12px;">宝贝已向您飞奔而去</p>
						</van-step>
					</van-steps>
				</div>
				<div style="margin-top: 20px;">
					注:音频制作完成后，我们将在24小时内给您发出，若有疑问，可在商城中咨询客服。
				</div>
			</div>
		</div>
		<div class="footer"><a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2024055047号-1</a></div>
	</div>
	
	</div>

</template>

<script>
	import axios from 'axios';
	import {
		Toast
	} from '@femessage/vant';
	import Recorder from 'js-audio-recorder'
	const baseUrl = 'https://www.daydata.cn:8443'
	export default {
		data() {
			return {
				phone: '',
				orderID: '',
				isLogin: false,
				msg: '',
				recorder: new Recorder({
					sampleRate: 44100, // 采样率
					bitRate: 128 // 比特率
				}),
				recorder: null,
				playTime: 0,
				timer: null,
				src: null,
				ifTijiao: false,
				active: 1,
				id:0,
				status:0
			};
		},
		created() {
			this.recorder = new Recorder()
		},
		methods: {
			onSubmit(values) {
				axios.get(baseUrl + '/ddgl/appOrderManagement/findByOrder?phone=' + this.phone + '&number=' + this.orderID)
					.then(response => {
						console.log(response.data)
						if (response.data.code == 200) {
							Toast.success('验证通过');
							this.isLogin = true
							this.id=response.data.id
							
							this.status=response.data.status
							if(this.status>0){
								this.ifTijiao=true
							}
						} else {
							Toast.fail('未查询到订单号');
						}
					})
					.catch(error => {
						Toast.fail('未查询到订单号');
					});
			},
			// 开始录音
			handleStart() {
				this.recorder = new Recorder()
				Recorder.getPermission().then(() => {
					console.log('开始录音')
					this.recorder.start() // 开始录音
				}, (error) => {
					alert(error)
				})
			},
			handleDestroy() {
				console.log('销毁实例')
				this.recorder.destroy() // 毁实例
				this.timer = null
			},
			handleStop() {
				console.log('停止录音')
				this.recorder.stop() // 停止录音
			},
			handlePlay() {
				console.log('播放录音')
				this.recorder.play() // 播放录音

				// 播放时长
				this.timer = setInterval(() => {
					try {
						this.playTime = this.recorder.getPlayTime()
					} catch (error) {
						this.timer = null
					}
				}, 100)
			},

			uploadRecord() {
				if (this.recorder == null || this.recorder.duration === 0) {
					Toast.fail('请先录音');
					return false
				}
				this.recorder.pause() // 暂停录音
				this.timer = null
				console.log('上传录音') // 上传录音
				Toast.success('提交后请勿离开页面，等待响应提示后页面会自动跳转');
				const formData = new FormData()
				const blob = this.recorder.getWAVBlob() // 获取wav格式音频数据
				// 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
				const newbolb = new Blob([blob], {
					type: 'audio/wav'
				})
				const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
				formData.append('file', fileOfBlob)
				formData.append('id', this.id)
				formData.append('uploadPath', 'app')
				const url = window.URL.createObjectURL(fileOfBlob)
				this.src = url

				axios.post(baseUrl + '/ddgl/appOrderManagement/uploadFile', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(response => {
						console.log(response.data)
						this.msg = response.data
						if (response.data.code == 200) {
							Toast.success('上传成功');
							this.ifTijiao = true
							this.msg = response.data
						} else {
							Toast.fail('上传失败请重试');
							this.msg = response.data
						}
					})
					.catch(error => {
						this.msg = error
						Toast.fail('未查询到订单号');
					});
				// const axios = require('axios')
				// axios.post(url, formData).then(res => {
				//   console.log(res.data.data[0].url)
				// })
			}
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.card {
		text-align: left;
	}

	.div1 {
		padding: 20px;
	}

	.biaodan {
		margin: 20px;
	}
	.footer{
		position: absolute;
		bottom: 20px !important;
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
</style>